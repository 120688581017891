import React from "react";
import Countdown from "react-countdown";
import "./CountdownTimer.css";

// Target Date: Feb 21, 2025, 4:00 PM EST
const targetDate = new Date("2025-02-21T16:00:00-05:00").getTime();

// Custom renderer to output days/hours/minutes/seconds in a block style
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div className="countdown-finished">The hackathon has started!</div>;
  }

  const d = String(days).padStart(2, "0");
  const h = String(hours).padStart(2, "0");
  const m = String(minutes).padStart(2, "0");
  const s = String(seconds).padStart(2, "0");

  return (
    <div className="countdown-container">
      <div className="time-block">
        <span className="value">{d}</span>
        <span className="label">Days</span>
      </div>
      <div className="time-block">
        <span className="value">{h}</span>
        <span className="label">Hours</span>
      </div>
      <div className="time-block">
        <span className="value">{m}</span>
        <span className="label">Minutes</span>
      </div>
      <div className="time-block">
        <span className="value">{s}</span>
        <span className="label">Seconds</span>
      </div>
    </div>
  );
};

export default function CountdownTimer() {
  return <Countdown date={targetDate} renderer={renderer} />;
}

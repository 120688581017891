import hacklytics2025logo from "../../Assets/Images/Branding/Hacklytics 2025 Logo.svg";
import React from "react";
import Section from "../../Components/Section";
import "./Home.css";
import CountdownTimer from "./CountdownTimer"; // The custom countdown we made

const HomePage = () => {
  return (
    <Section id="hero">
      <div className="hero-flex">
        <div className="flex-left">
          <h1 className="hero-major">Hacklytics 2025</h1>
          <h2 className="hero-minor">Jurassic Age</h2>
          <h2 className="hero-date">February 21-23</h2>

          <CountdownTimer />

          <h2 className="hero-presented">Presented by</h2>
          <h2 className="hero-presented">Data Science @ GT</h2>
          {/* <h3 className="hero-date-mini">
        *Registration extended to January 24th*
      </h3> */}
        </div>
        <div className="flex-right">
          <div className="logo-stars">
            <img
              className="hero-logo"
              src={hacklytics2025logo}
              alt="hacklytics 2025 logo"
            />
          </div>
        </div>
      </div>
      <a className="continue-reading" href="#whenwhere">
        <span className="text">continue</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default HomePage;

import React from "react";
import Section from "../../Components/Section";
import pterodactyl from "../../Assets/Images/Branding/Pterodactyl.png"; // Adjust the path as needed
import fossil from "../../Assets/Images/Branding/Fossil.png"; // Adjust the path as needed
import "./Home.css";

const WhatIsPage = () => {
  return (
    <Section id="whatis">
      <div className="whatis-flex">
        <div className="title-flex">
          <img
            src={pterodactyl}
            alt="Pterodactyl"
            className="pterodactyl-img"
          />
          <h2 className="major">What is Hacklytics?</h2>
        </div>
        <p className="mini">
          Hacklytics is a 36-hour data science-focused hackathon hosted by Data
          Science @ GT, the largest data science club at Georgia Tech. Each
          year, hackers across the world join us in creating innovative
          data-driven solutions to real problems.
        </p>
        <div className="title-flex">
          <h2 className="major">Why Hacklytics?</h2>
          <img src={fossil} alt="Fossil" className="fossil-img" />
        </div>
        <p className="mini">
          Our goal is to equip you with hands-on data science experience and
          help you create impactful projects. At the same time, you can connect
          with hackers from various backgrounds, build a diverse network, and
          have fun!
        </p>
      </div>
      <a className="continue-reading" href="#schedule">
        <span className="text">Schedule</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default WhatIsPage;

import { useState } from "react";
import "./ScheduleSection.scss";

import upper from "../../Assets/Images/Branding/small_upper_footprint.png";
import lower from "../../Assets/Images/Branding/small_upper_footprint.png";

const ScheduleSection = (params) => {
  const [day, setDay] = useState(1);

  return (
    <div className="events-schedule-section">
      <div className="tabs">
        <div className="schedule-tabs">
          <button
            className={`${"tab-button"} ${day === 1 ? "_active" : ""}`}
            onClick={() => {
              if (day !== 1) setDay(1);
            }}
          >
            Friday
          </button>

          <button
            className={`${"tab-button"} ${day === 2 ? "_active" : ""}`}
            onClick={() => {
              if (day !== 2) setDay(2);
            }}
          >
            Saturday
          </button>

          <button
            className={`${"tab-button"} ${day === 3 ? "_active" : ""}`}
            onClick={() => {
              if (day !== 3) setDay(3);
            }}
          >
            Sunday
          </button>
          <span className="slider" role="presentation"></span>
        </div>
      </div>

      <table className="schedule-table">
        <tbody>
          {day === 1 ? (
            <>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="" />
                </td>
                <td className="time">4:30pm</td>
                <td className="event">Check-in starts</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="" />
                </td>
                <td className="time">5:45pm - 6:45pm</td>
                <td className="event">Opening Ceremony</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">7:00pm - 8:15pm</td>
                <td className="event">Sponsor Fair</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">8:00pm - 9:00pm</td>
                <td className="event">Friday Dinner</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">9:30pm</td>
                <td className="event">Hacking starts</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">10:00pm - 11:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">11:30pm</td>
                <td className="event">Friday Midnight Snack</td>
                <td className="location">TBD</td>
              </tr>
            </>
          ) : null}
          {day === 2 ? (
            <>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time"> 10:00am</td>
                <td className="event">Saturday Breakfast</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">10:00am - 11:00am</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">11:00am - 12:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">1:00pm - 2:00pm</td>
                <td className="event">Saturday Lunch</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">2:00pm - 3:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">3:00pm - 4:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">4:00pm - 5:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">5:00pm - 6:00pm</td>
                <td className="event">Activity</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">6:00pm - 7:00pm</td>
                <td className="event">TBD Workshop</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">9:00pm - 10:00pm</td>
                <td className="event">Saturday Dinner</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">11:45pm</td>
                <td className="event">Saturday Midnight Snack</td>
                <td className="location">TBD</td>
              </tr>
            </>
          ) : null}
          {day === 3 ? (
            <>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">9:00am</td>
                <td className="event">Hacking ends</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">9:30am - 10:30am</td>
                <td className="event">Sunday Breakfast</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">11:00am - 2:00pm</td>
                <td className="event">Judging</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={lower} alt="airplane-pic" />
                </td>
                <td className="time">2:00pm</td>
                <td className="event">Sunday Lunch</td>
                <td className="location">TBD</td>
              </tr>
              <tr>
                <td className="img-container">
                  <img className="airplane" src={upper} alt="airplane-pic" />
                </td>
                <td className="time">4:00pm - 4:30pm</td>
                <td className="event">Closing Ceremony</td>
                <td className="location">TBD</td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleSection;
